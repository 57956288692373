<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <div class="dosiin_wrapper-bar dosiin_mb-16">
      <Breadcrumbs :breadcumbs="breadcumbs" />
      <div class="dosiin-tab-bar-pc dosiin_mb-16">
        <div class="tab-bar-pc-content">
          <div class="title-page d-flex align-items-center justify-content-between">
            <div class="title-all-categories">
              {{ title }}
            </div>
            <div class="tab-bar-content-quantity">
              <span class="quantity" v-text="numberFormat(totalProducts)"></span>
              <span class="text-quantity">Sản phẩm tìm thấy</span>
            </div>
          </div>
        </div>

        <FitersBarPc :maxPrice="maxPrice" :totalProducts="totalProducts" />
      </div>
    </div>

    <div class="page-section">
      <ProductInifityLoader
        :query="params"
        @total-products="(total) => (totalProducts = total)"
        @max-price="(max) => (maxPrice = max)"
      />
    </div>
  </div>
</template>

<script>
import ProductInifityLoader from "@/components/product/ProductInifityLoader";
import FitersBarPc from "@/components/filters/FiltersBarPc";

import { ref, inject } from "vue";

export default {
  components: {
    ProductInifityLoader,
    FitersBarPc,
  },
  setup() {
    const title = "Sản phẩm mới";
    const totalProducts = ref(0);
    const maxPrice = ref(0);
    const type = inject("type");
    const params = inject("params");
    const breadcumbs = [{ title: title, route: "#" }];

    return {
      maxPrice,
      title,
      breadcumbs,
      totalProducts,
      type,
      params,
    };
  },
};
</script>
