<template>
  <div ref="scrollRef">
    <slot></slot>
    <div v-if="isLoading" style="text-align: center">
        <LoadingComponent />
    </div>
  </div>
</template>

<script>
    import { onMounted, onUnmounted, toRefs } from "vue";
    import infiniteScroll from "@/plugins/infinite-loading/composables/infiniteScroll";

    export default {
        props: {
            noResult: {
                type: Boolean,
                default: false,
            },
            isLoading :{
                type: Boolean,
                default: false,
            }
        },
        setup(props, { emit }) {

            const { noResult , isLoading } = toRefs(props);

            const {
                handleScroll,
                scrollRef,
                loading
            } = infiniteScroll(noResult, emit);
            
            onMounted(() => {
                window.addEventListener("scroll", handleScroll)
            });

            onUnmounted(() => {
                window.removeEventListener("scroll", handleScroll)
            });

            return {
                scrollRef,
                loading,
                isLoading
            }
        },
    }
</script>