<template>
  <div class="dosiin_filter-list-item filter-list-item border-top-grey">
    <button
      class="title text-dark-lg dosiin_filter-category"
      type="button"
      @click.prevent="state.showFilterBrands = true"
    >
      Thương hiệu
      <span class="filter-list-item_quantity">
        <div
          v-show="dataProvide.length"
          class="dosiin_filter-selected-option-quantity filter-quantity text-grey-md text-pink-gradient"
        >
          +{{ dataProvide.length }}
        </div>
        <i class="dsi dsi-chevron-right dsi-s16"></i>
      </span>
    </button>
    <div
      class="dosiin_swipable-tag-list filter-tag-list swipable-tag-list-slider dosiin_swipable-tag-list-slider"
    >
      <Swiper
        v-show="dataProvide.length"
        :slidesPerView="'auto'"
        :observer="true"
        :observeParents="true"
        :spaceBetween="6"
        class="dosiin_tag-list tag-list swiper-wrapper"
      >
        <SwiperSlide
          class="swiper-slide dosiin_mr-2 swiper-slide_button clear-btn btn-circle dosiin_btn-clear-filter swiper-slide-active"
          @click="removeSelected('all')"
        >
          <i class="dsi dsi-close dsi-s16"></i>
        </SwiperSlide>

        <SwiperSlide
          class="tag-item dosiin_mr-2"
          v-for="(brand, i) in dataProvide"
          :key="i"
        >
          <span class="text">{{ brand.name }}</span>
          <button
            class="dosiin_btn-clear-single-tag clear-tag-btn"
            @click="removeSelected(brand.id)"
          >
            <i class="dsi dsi-close dsi-s12"></i>
          </button>
        </SwiperSlide>
      </Swiper>
    </div>
    <Modal
      v-model="state.showFilterBrands"
      @close="closeFilterBrands"
      :modalTypeClass="filterModalClass"
    >
      <template v-slot:header>
        <h2 class="text-grey-lg">Thương hiệu</h2>
        <div class="back-header-right-side"></div>
      </template>
      <template v-slot:body>
        <LoadingComponent v-if="!state.fetched" />
        <form
          v-else
          class="dosiin_filter-form dosiin_filter-brands-form cm-processed-form"
          ref="filterForm"
        >
          <div class="filter-brands-group brand-list">
            <div class="filter-group-search">
              <div class="filter-input-search">
                <input
                  class="search-input"
                  type="text"
                  placeholder="Bạn đang tìm thương hiệu gì?"
                  @input="searchBrand($event.target.value)"
                />
                <span class="search-icon"> </span>
              </div>
            </div>
            <div class="filter-list-item filter-list-item-branch">
              <div class="selection-buttons selection-button-type">
                <input
                  v-model="typeBrand"
                  class="selection-buttons_item input-radio"
                  id="filterBrand-0"
                  type="radio"
                  name="filterBrand"
                  value=""
                  checked=""
                />
                <label class="width_filter" for="filterBrand-0"
                  ><span class="text text-dark-md">Tất cả </span></label
                >
                <input
                  v-model="typeBrand"
                  class="selection-buttons_item input-radio"
                  id="filterBrand-1"
                  type="radio"
                  name="filterBrand"
                  value="A"
                />
                <label class="width_filter" for="filterBrand-1"
                  ><span class="text text-dark-md">Nhà phân phối</span></label
                >
                <input
                  v-model="typeBrand"
                  class="selection-buttons_item input-radio"
                  id="filterBrand-2"
                  type="radio"
                  name="filterBrand"
                  value="D"
                />
                <label class="width_filter" for="filterBrand-2"
                  ><span class="text text-dark-md">Tiếp thị liên kết</span></label
                >
              </div>
              <Popper :class="$style.dark" arrow disableClickAway>
                <button type="button">
                  <i class="dsi dsi-info dsi-s24"></i>
                </button>

                <template #content>
                  <div style="width: 207px">
                    <b>Nhà cung cấp:</b> Các thương hiệu đang bán hàng trực tiếp trên
                    Dosi-in.com. Các chính sách khuyến mãi, thanh toán và đổi trả sẽ được
                    áp dùng theo chính sách của Dosiin.
                    <br />
                    <br />
                    <b>Tiếp thị liên kết:</b> Các thương hiệu đang hợp tác quảng cáo với
                    Dosiin. Người dùng sau khi nhấn “Đặt hàng ngay” trên Dosi-in.com sẽ
                    được chuyển đến trang web của thương hiệu để hoàn tất việc mua hàng.
                    Các chính sách khuyến mãi, thanh toán và đổi trả sẽ được áp dụng theo
                    chính sách của thương hiệu đó.
                  </div>
                </template>
              </Popper>
            </div>
            <div class="brand-list_check-all">
              <div class="total_brand">
                <span class="quantity">{{ numberFormat(state.countResult) }}</span>
                <span class="text-quantity">thương hiệu</span>
              </div>
              <div
                class="check_all-brand"
                v-show="state.showSelectAll"
                @click="selectAllFiltered"
              >
                <span
                  class="quantity choose"
                  v-text="state.selectAll ? 'Bỏ chọn' : 'Chọn tất cả'"
                ></span>
                <i class="dsi dsi-check"></i>
              </div>
            </div>
            <div class="scroll-bar-wrap-inner">
              <div class="filter_branch_wrapper scroll-box-modal">
                <ul
                  class="group-wrapper"
                  v-for="(brands, alphaText) in listBrandAphal"
                  :key="alphaText"
                >
                  <li class="group-header">{{ alphaText }}</li>
                  <li class="filter-item" v-for="(brand, i) in brands.items" :key="i">
                    <label class="filter-label">
                      <input
                        v-model="listIds"
                        class="checkbox-input"
                        type="checkbox"
                        name="filterOption"
                        :value="brand.company_id"
                      />
                      <img
                        width="40"
                        height="40"
                        v-lazy="_companyAvatar(brand.logos.theme)"
                      />
                      <span class="filter-label-text" v-text="brand.company_name"></span>
                      <i class="check-icon"></i>
                    </label>
                  </li>
                </ul>
                <div class="cover-bar_modal"></div>
              </div>
            </div>
          </div>
        </form>
      </template>

      <template v-slot:footer>
        <button
          class="btn-rounded dosiin_filter-reset-btn cm-reset-link dossin_btn-whitebg"
          type="button"
          @click="resetFilters"
          :disabled="listIds.length === 0"
        >
          <div class="btn-rounded_child">
            <span class="text-gradient">Đặt lại</span>
          </div>
        </button>
        <button
          class="btn-rounded btn-rounded-fill dosiin_filter-apply-btn"
          type="button"
          @click="filterByIds"
          :disabled="listIds.length === 0"
        >
          Áp dụng
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modals/Modal";
import { ref, watch, reactive } from "vue";
import { serialize } from "@/utlis/serialize";
import {
  _groupByAlphabet,
  _trimString,
  _compareObjects,
  _itemExists,
} from "@/utlis/helpers";
import BrandServices from "@/services/brand";
import { FreeMode, Navigation } from "swiper";
import { _dsEmptyObject } from "@/utlis/helpers";
import Popper from "vue3-popper";

export default {
  name: "filterBrands",
  components: { Modal, Popper },
  emits: ["update"],
  props: {
    filterModalClass: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const typeBrand = ref(null);
    const listBrandAphal = ref([]);
    const groups = ref([]);
    const dataProvide = ref(props.modelValue);
    const listIds = ref([]);
    const filterForm = ref(null);
    const filterModalClass = ref(props.filterModalClass);
    const state = reactive({
      showFilterBrands: false,
      fetched: false,
      listBrand: [],
      listBrandFilterd: [],
      countResult: 0,
      showSelectAll: false,
      selectAll: false,
    });

    const closeFilterBrands = () => {
      state.showFilterBrands = false;
    };

    watch(
      () => state.showFilterBrands,
      () => {
        if (state.fetched === false) {
          getBrands();
        }
      }
    );

    const getBrands = async () => {
      var params = { filterPage: true, pageType: "products" };
      const response = await BrandServices.fetchBrands(serialize(params));

      if (response.data) {
        state.listBrand = response.data.brandList;
        listBrandAphal.value = _groupByAlphabet(response.data.brandList, "company_name");
        groups.value = Object.keys(listBrandAphal.value);
        state.countResult = state.listBrand.length;
      }

      state.fetched = true;
    };

    const getBrandsFromGroup = (groupSelect) => {
      listIds.value = [];
      listBrandAphal.value = _groupByAlphabet(state.listBrand, "company_name");
      if (groupSelect === "all") {
        state.showSelectAll = false;
        state.selectAll = false;

        state.countResult = state.listBrand.length;
        return;
      }

      if (listBrandAphal.value[groupSelect]) {
        var originGroup = _groupByAlphabet(state.listBrand, "company_name");
        var groupValueSelected = originGroup[groupSelect];
        // assign filtered values to group
        state.countResult = groupValueSelected.items.length;
        state.listBrandFilterd = groupValueSelected.items;
        listBrandAphal.value = {};
        state.showSelectAll = true;
        listBrandAphal.value[groupSelect] = groupValueSelected;
      }
    };

    const searchBrand = (value) => {
      var results = [];
      listIds.value = [];
      const objects = state.listBrand;

      var search = new RegExp(value, "i"); // prepare a regex object
      results = objects.filter((item) => search.test(item["company_name"]));
      //show results
      state.listBrandFilterd = results;
      listBrandAphal.value = _groupByAlphabet(results, "company_name");
      state.countResult = results.length;

      if (value.length === 0) {
        state.showSelectAll = false;
      } else {
        state.showSelectAll = true;
      }
    };

    const filterByIds = () => {
      dataProvide.value = [];
      state.listBrand.forEach((item) => {
        if (listIds.value.includes(item.company_id))
          dataProvide.value.push({ id: item.company_id, name: item.company_name });
      });

      emit("update", dataProvide.value);

      closeFilterBrands();
    };

    const removeSelected = (value) => {
      if (value === "all") {
        dataProvide.value = [];
        state.listBrandFilterd = [];
        listIds.value = [];
      } else {
        dataProvide.value = dataProvide.value.filter((brand) => brand.id !== value);
      }

      emit("update", dataProvide.value);
    };

    const resetFilters = () => {
      filterForm.value.reset();
      listIds.value = [];
      dataProvide.value = [];
      emit("update", dataProvide.value);
    };

    const selectAllFiltered = () => {
      listIds.value = [];
      state.selectAll = !state.selectAll;
      if (state.selectAll)
        state.listBrandFilterd.forEach((item) => listIds.value.push(item.company_id));
    };

    watch(
      () => typeBrand.value,
      (newType) => {
        var results = [];
        listIds.value = [];
        if (newType) {
          results = state.listBrand.filter((item) => item.status == newType);
          state.listBrandFilterd = results;
          state.showSelectAll = true;
          state.selectAll = false;
        } else {
          state.showSelectAll = false;
          results = state.listBrand;
        }

        state.countResult = results.length;

        listBrandAphal.value = _groupByAlphabet(results, "company_name");
      }
    );

    return {
      state,
      listIds,
      listBrandAphal,
      groups,
      dataProvide,
      filterModalClass,
      typeBrand,
      //refs
      filterForm,
      // methods
      resetFilters,
      closeFilterBrands,
      getBrandsFromGroup,
      filterByIds,
      searchBrand,
      removeSelected,
      selectAllFiltered,
      // swiper
      modules: [FreeMode, Navigation],
    };
  },
};
</script>

<style module>
.dark {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: white;
  --popper-theme-border-width: 0px;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 16px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 150%;
}
</style>

<style scoped>
:deep(.popper) {
  transform: translate(110px, 40px) !important;
}

:deep(.popper #arrow) {
  transform: translate(220px, 0px) !important;
}

.check_all-brand {
  cursor: pointer;
}

@media screen and (max-width: 414px) {
  .dosiin_filter-modal .filter-list-item-branch {
    position: relative;
    z-index: 1;
  }

  :deep(.popper) {
    transform: translate(70px, 40px) !important;
  }
}
</style>
