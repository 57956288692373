<template>
  <div class="dosiin_filter-list-item filter-list-item border-top-grey">
    <button
      class="title text-dark-lg dosiin_filter-category"
      type="button"
      @click.prevent="showFilterSizes = true"
    >
      Kích thước
      <span class="filter-list-item_quantity">
        <div
          class="dosiin_filter-selected-option-quantity filter-quantity text-grey-md"
        ></div>
        <i class="dsi dsi-chevron-right dsi-s16"></i>
      </span>
    </button>

    <div
      class="dosiin_swipable-tag-list filter-tag-list swipable-tag-list-slider dosiin_swipable-tag-list-slider"
    >
      <Swiper
        v-show="dataProvide.length"
        :slidesPerView="'auto'"
        :observer="true"
        :observeParents="true"
        :spaceBetween="8"
        class="dosiin_tag-list tag-list swiper-wrapper"
      >
        <SwiperSlide
          class="swiper-slide dosiin_mr-2 swiper-slide_button clear-btn btn-circle dosiin_btn-clear-filter swiper-slide-active"
          @click="removeSelected('all')"
        >
          <i class="dsi dsi-close dsi-s16"></i>
        </SwiperSlide>

        <SwiperSlide
          class="tag-item dosiin_mr-2"
          v-for="(item, i) in dataProvide"
          :key="i"
        >
          <span class="text">{{ item.name }}</span>
          <button
            class="dosiin_btn-clear-single-tag clear-tag-btn"
            @click="removeSelected(item.id)"
          >
            <i class="dsi dsi-close dsi-s12"></i>
          </button>
        </SwiperSlide>
      </Swiper>
    </div>

    <Modal
      v-model="showFilterSizes"
      @close="closeFilterSizes"
      :modalTypeClass="filterModalClass"
    >
      <template v-slot:header>
        <h2 class="text-grey-lg">Màu sắc</h2>
        <div class="back-header-right-side"></div>
      </template>

      <template v-slot:body>
        <LoadingComponent v-if="!fetched" />
        <form
          v-else
          class="dosiin_filter-form dosiin_filter-colors-form"
          ref="filterForm"
        >
          <div class="filter-group-search">
            <div class="filter-input-search">
              <input
                class="search-input"
                type="text"
                placeholder="Bạn đang tìm size gì?"
                @input="searchSize($event.target.value)"
              />
              <span class="search-icon"></span>
            </div>
          </div>

          <ul class="group-wrapper filter-colors">
            <li class="filter-item" v-for="(item, i) in listSize" :key="i">
              <label class="filter-label">
                <input
                  v-model="listIds"
                  class="checkbox-input"
                  type="checkbox"
                  name="filterOption"
                  :value="item.sizeId"
                />
                <span class="filter-label-text">{{ item.size_name }}</span>
                <i class="check-icon"></i>
              </label>
            </li>
          </ul>
        </form>
      </template>

      <template v-slot:footer>
        <button
          class="btn-rounded dosiin_filter-reset-btn cm-reset-link dossin_btn-whitebg"
          type="button"
          @click="resetFilters"
          :disabled="listIds.length === 0"
        >
          <div class="btn-rounded_child">
            <span class="text-gradient">Đặt lại</span>
          </div>
        </button>
        <button
          class="btn-rounded btn-rounded-fill dosiin_filter-apply-btn"
          type="button"
          @click="filterByIds"
          :disabled="listIds.length === 0"
        >
          Áp dụng
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modals/Modal";
import { ref, watch } from "vue";
import SizeColorService from "@/services/sizecolor";
import { serialize } from "@/utlis/serialize";

export default {
  name: "filterBrands",
  components: { Modal },
  props: {
    filterModalClass: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const showFilterSizes = ref(false);
    const fetched = ref(false);
    const listSize = ref([]);
    const dataProvide = ref(props.modelValue);
    const listIds = ref([]);
    const filterForm = ref(null);
    const filterModalClass = ref(props.filterModalClass);
    const closeFilterSizes = () => {
      showFilterSizes.value = false;
    };
    var listSizeOrigin = [];
    watch(
      () => showFilterSizes.value,
      () => {
        if (fetched.value === false) {
          getSizes();
        }
      }
    );

    const getSizes = async () => {
      var params = { filterPage: true, pageType: "products", getSize: true };

      const response = await SizeColorService.fetchSizeColor(serialize(params));

      if (response.data) {
        listSizeOrigin = listSize.value = response.data.sizeList;
      }

      fetched.value = true;
    };

    const filterByIds = () => {
      dataProvide.value = [];
      for (const [key, item] of Object.entries(listSize.value)) {
        if (listIds.value.includes(item.sizeId))
          dataProvide.value.push({
            id: item.sizeId,
            name: item.size_name,
          });
      }

      emit("update", dataProvide.value);
      closeFilterSizes();
    };

    const removeSelected = (categoryId) => {
      if (categoryId === "all") {
        dataProvide.value = [];
      } else {
        dataProvide.value = dataProvide.value.filter(
          (category) => category.id !== categoryId
        );
      }

      emit("update", dataProvide.value);
    };

    const resetFilters = () => {
      filterForm.value.reset();
      listIds.value = [];
      dataProvide.value = [];
      emit("update", dataProvide.value);
    };

    const searchSize = (value) => {
      const objects = listSizeOrigin;
      var search = new RegExp(value, "i"); // prepare a regex object
      listSize.value = objects.filter((item) => search.test(item["size_name"]));
    };

    return {
      showFilterSizes,
      fetched,
      listSize,
      dataProvide,
      listIds,
      filterForm,
      filterModalClass,
      // methods
      searchSize,
      closeFilterSizes,
      filterByIds,
      removeSelected,
      resetFilters,
    };
  },
};
</script>
