<template>
  <ProductsNewArrivalMB v-if="layout == 4" />
  <ProductsNewArrivalPC v-else />
</template>

<script>
import ProductsNewArrivalMB from "@/pages/mb/ProductsNewArrival";
import ProductsNewArrivalPC from "@/pages/pc/ProductsNewArrival";
import { provide, ref } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    ProductsNewArrivalMB,
    ProductsNewArrivalPC,
  },
  setup() {
    const route = useRoute();
    const type = "new_arrival";

    const params = ref({
      page: 1,
      type_category: route.query.type_category ? route.query.type_category : "",
      sort_by: route.query.sort_by ? route.query.sort_by : "", //popularity , percent_discount
      sort_order: route.query.sort_order ? route.query.sort_order : "",
      sizes: route.query.sizes ? route.query.sizes : [],
      company_ids: route.query.company_ids ? route.query.company_ids : [],
      cid: route.query.cid ? route.query.cid : [],
      color: route.query.color ? route.query.color : [],
      on_sale_from: route.query.on_sale_from ? route.query.on_sale_from : null,
      on_sale_to: route.query.on_sale_to ? route.query.on_sale_to : null,
      load_new_arrival: "Y",
    });

    provide("type", type);
    provide("params", params);
  },
};
</script>
