<template>
  <div class="dosiin-tab-bar-pc">
    <div class="tab-bar-filter">
      <div class="tab-bar-filter-item" @click="openFilters">
        <span class="text-item">Bộ lọc</span>
        <p class="dosiin_ml-2" v-if="currentMatchFilter">
          <span>{{ currentMatchFilter }}</span>
        </p>
        <div class="icon_filter"><i class="dsi dsi-filter-setting"></i></div>
      </div>
      <div class="tab-bar-filter-item-icon">
        <svg
          width="1"
          height="32"
          viewBox="0 0 1 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="1" height="32" fill="#E6E6E6"></rect>
        </svg>
      </div>
      <!-- <div class="tab-bar-filter-item tab-bar-filter-item-reset">
                <div class="icon_filter">
                    <i class="dsi dsi-close dsi-s16 dosiin_ml-0"></i>
                </div>
            </div> -->
      <div
        class="tab-bar-filter-item"
        :class="{ 'filter-item_old': currentParams.type_category }"
        v-click-outside="() => (state.showTypeCategory = false)"
      >
        <button class="d-flex" @click="toggleSort('showTypeCategory')">
          <span class="text-item">Phân loại</span>
          <i
            :class="['dsi', state.showTypeCategory ? 'dsi-drop-up' : 'dsi-drop-down']"
          ></i>
        </button>

        <Transition duration="550" name="nested">
          <div v-if="state.showTypeCategory" class="drop-down-category-box">
            <div class="drop-down-color-filter-inner">
              <img :src="_polygon" width="15" height="13" alt="Polygon" />
            </div>
            <div class="dropdown_color-ttl dosiin_mb-2 dosiin_pd-16">
              <div class="color-ttl">
                <h5>Phân loại</h5>
              </div>
            </div>

            <div class="selection-buttons w-2">
              <input
                v-model="currentParams.type_category"
                class="selection-buttons_item input-radio"
                id="type_c-0"
                name="type_category"
                type="radio"
                value=""
                :checked="
                  currentParams.type_category == '' || currentParams.type_category == null
                "
              />
              <label for="type_c-0">
                <span class="text text-dark-md">Toàn bộ</span>
              </label>
              <input
                v-model="currentParams.type_category"
                class="selection-buttons_item input-radio"
                id="type_c-1"
                name="type_category"
                type="radio"
                value="male"
                :checked="currentParams.type_category == 'male'"
              />
              <label for="type_c-1">
                <span class="text text-dark-md">Nam</span>
              </label>
              <input
                v-model="currentParams.type_category"
                class="selection-buttons_item input-radio"
                id="type_c-2"
                name="type_category"
                type="radio"
                value="female"
                :checked="currentParams.type_category == 'female'"
              />
              <label for="type_c-2">
                <span class="text text-dark-md">Nữ</span>
              </label>
              <input
                v-model="currentParams.type_category"
                class="selection-buttons_item input-radio"
                id="type_c-3"
                name="type_category"
                type="radio"
                value="other"
                :checked="currentParams.type_category == 'other'"
              />
              <label for="type_c-3">
                <span class="text text-dark-md">Phụ kiện</span>
              </label>
            </div>
          </div>
        </Transition>
      </div>
      <div
        class="tab-bar-filter-item"
        :class="{
          'filter-item_old': currentParams.sort_by != 'random' && currentParams.sort_by,
        }"
        v-click-outside="() => (state.showSortBy = false)"
      >
        <button class="d-flex" @click="toggleSort('showSortBy')">
          <span class="text-item">Sắp xếp theo</span>
          <i :class="['dsi', state.showSortBy ? 'dsi-drop-up' : 'dsi-drop-down']"></i>
        </button>
        <Transition duration="550" name="nested">
          <div v-if="state.showSortBy" class="drop-down-category-box">
            <div class="drop-down-color-filter-inner">
              <img :src="_polygon" width="15" height="13" alt="Polygon" />
            </div>
            <div class="dropdown_color-ttl dosiin_mb-2 dosiin_pd-16">
              <div class="color-ttl">
                <h5>Chọn trạng thái</h5>
              </div>
            </div>
            <div class="selection-buttons w-2">
              <input
                v-model="currentParams.sort_by"
                class="selection-buttons_item input-radio"
                id="filterStatus-0"
                name="sort_by"
                type="radio"
                value="random"
                :checked="
                  currentParams.sort_by == 'random' || currentParams.sort_by == null
                "
              />
              <label for="filterStatus-0"
                ><span class="text text-dark-md">Toàn bộ</span></label
              >
              <input
                v-model="currentParams.sort_by"
                class="selection-buttons_item input-radio"
                id="filterStatus-1"
                name="sort_by"
                type="radio"
                value="popularity"
                :checked="currentParams.sort_by == 'popularity'"
              />
              <label for="filterStatus-1"
                ><span class="text text-dark-md">Nổi bật</span></label
              >

              <input
                v-model="currentParams.sort_by"
                class="selection-buttons_item input-radio"
                id="filterStatus-2"
                name="sort_by"
                type="radio"
                value="timestamp"
                :checked="currentParams.sort_by == 'timestamp'"
              />
              <label for="filterStatus-2"
                ><span class="text text-dark-md">Mới về</span></label
              >
              <input
                v-model="currentParams.sort_by"
                class="selection-buttons_item input-radio"
                id="filterPrice-3"
                name="sort_by"
                type="radio"
                value="on_sale_desc"
                :checked="currentParams.sort_by == 'on_sale'"
              />
              <label for="filterPrice-3"
                ><span class="text text-dark-md">Giá giảm nhiều</span></label
              >

              <input
                v-model="currentParams.sort_by"
                class="selection-buttons_item input-radio"
                id="filterPrice-4"
                name="sort_by"
                type="radio"
                value="sort_by_price_desc"
                :checked="
                  currentParams.sort_by == 'price' && currentParams.sort_order == 'desc'
                "
              />
              <label for="filterPrice-4"
                ><span class="text text-dark-md">Giá cao trước</span></label
              >
              <input
                v-model="currentParams.sort_by"
                class="selection-buttons_item input-radio"
                id="filterPrice-5"
                name="sort_by"
                type="radio"
                value="sort_by_price_asc"
                :checked="
                  currentParams.sort_by == 'price' && currentParams.sort_order == 'asc'
                "
              />
              <label for="filterPrice-5"
                ><span class="text text-dark-md">Giá thấp trước </span></label
              >
            </div>
          </div>
        </Transition>
      </div>
      <div
        class="tab-bar-filter-item"
        :class="{ 'filter-item_old': currentParams.price_from || currentParams.price_to }"
        v-click-outside="() => (state.showSortPrice = false)"
      >
        <button class="d-flex" @click="toggleSort('showSortPrice')">
          <span class="text-item">Giá cả</span>
          <i :class="['dsi', state.showSortPrice ? 'dsi-drop-up' : 'dsi-drop-down']"></i>
        </button>
        <Transition duration="550" name="nested">
          <div v-if="state.showSortPrice" class="drop-down-category-box">
            <div class="drop-down-color-filter-inner">
              <img :src="_polygon" width="15" height="13" alt="Polygon" />
            </div>
            <div class="dropdown_color-ttl dosiin_mb-2 dosiin_pd-16">
              <div class="color-ttl">
                <h5>Chọn mức giá</h5>
              </div>
            </div>
            <div class="dosiin_pd-016">
              <div class="section-title slide-view-container">
                <div class="title-view-container center">
                  <span>
                    Giá từ <b>{{ numberFormat(pricesRange[0]) }} ₫ </b> đến
                    <b>{{ numberFormat(pricesRange[1]) }} ₫</b>
                  </span>
                </div>
                <Slider
                  class="view-container"
                  v-model="pricesRange"
                  :min="0"
                  :max="maxPrice"
                  :step="1000"
                  style="width: 100%"
                  :tooltips="false"
                ></Slider>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>

    <MainFilters
      @prices-range="(prices) => (pricesRange = prices)"
      v-model="currentParams"
      :filterCategory="filterCategory"
      :filterBrand="filterBrand"
      :totalProducts="totalProducts"
      :maxPrice="maxPrice"
      filterModalClass="modal_filter-inner"
      ref="mainFilters"
    />
  </div>
</template>

<script>
import { ref, reactive, inject, watch, toRefs, toRef } from "vue";
import vClickOutside from "click-outside-vue3";
import MainFilters from "@/components/filters/Filters";
import Slider from "@vueform/slider";
export default {
  components: {
    MainFilters,
    Slider,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    totalProducts: {
      type: [Number, String],
      default: 0,
    },
    maxPrice: {
      type: [Number, String],
      default: 0,
    },
    filterCategory: {
      type: Boolean,
      default: true,
    },
    filterBrand: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const currentParams = inject("params");
    const mainFilters = ref(null);
    const currentMatchFilter = inject("currentMatchFilter");
    const pricesRange = ref([0, 0]);

    const state = reactive({
      showTypeCategory: false,
      showSortBy: false,
      showSortPrice: false,
    });

    const toggleSort = (type) => {
      Object.keys(state).forEach(function (key, value) {
        if (key !== type) state[key] = false;
      });

      mainFilters.value.show = false;
      state[type] = !state[type];
    };

    watch(
      () => props.maxPrice,
      () => {
        pricesRange.value[1] = props.maxPrice;
      }
    );

    watch(
      () => pricesRange.value,
      () => {
        currentParams.value.price_from = pricesRange.value[0];
        currentParams.value.price_to = pricesRange.value[1];
      }
    );

    const openFilters = () => {
      Object.keys(state).forEach(function (key) {
        state[key] = false;
      });
      mainFilters.value.show = !mainFilters.value.show;
    };

    return {
      state,
      pricesRange,
      currentParams,
      currentMatchFilter,
      ...toRefs(props),
      // methods,
      mainFilters,
      openFilters,
      toggleSort,
    };
  },
};
</script>
