<template>
  <Modal
    v-model="show"
    @close="closeFilters"
    :modalTypeClass="filterModalClass"
    wrapperClass="filter-main-modal"
  >
    <template v-slot:header>
      <h2 class="text-grey-lg">Bộ lọc</h2>
      <div class="back-header-right-side"></div>
    </template>

    <template v-slot:body>
      <form ref="filterForm">
        <div class="filter-list filter-type-list">
          <div class="filter-list-item dosiin_pt-4 dosiin_pb-0">
            <p class="title text-dark-lg">Phân loại</p>
            <div class="selection-buttons w-2 dosiin_mt-2">
              <input
                v-model="filterValues.type_category"
                class="selection-buttons_item input-radio"
                id="type_c-0"
                name="type_category"
                type="radio"
                :value="null"
                :checked="
                  filterValues.type_category == '' || filterValues.type_category == null
                "
              />
              <label class="filter_label-item" for="type_c-0">
                <span class="text text-dark-md">Toàn bộ</span>
              </label>
              <input
                v-model="filterValues.type_category"
                class="selection-buttons_item input-radio"
                id="type_c-1"
                name="type_category"
                type="radio"
                value="male"
              />
              <label class="filter_label-item" for="type_c-1">
                <span class="text text-dark-md">Nam</span>
              </label>
              <input
                v-model="filterValues.type_category"
                class="selection-buttons_item input-radio"
                id="type_c-2"
                name="type_category"
                type="radio"
                value="female"
              />
              <label class="filter_label-item" for="type_c-2">
                <span class="text text-dark-md">Nữ</span>
              </label>

              <input
                v-model="filterValues.type_category"
                class="selection-buttons_item input-radio"
                id="type_c-3"
                name="type_category"
                type="radio"
                value="other"
              />
              <label class="filter_label-item" for="type_c-3">
                <span class="text text-dark-md">Phụ kiện</span>
              </label>
            </div>
          </div>
          <div class="filter-list-item dosiin_pt-4 dosiin_pb-0">
            <p class="title text-dark-lg">Sắp xếp theo</p>
            <div class="selection-buttons w-2 dosiin_mt-2">
              <input
                v-model="filterValues.sort_by"
                class="selection-buttons_item input-radio"
                id="filterStatus-0"
                name="sort_by"
                type="radio"
                value="random"
                :checked="
                  filterValues.sort_by == 'random' || filterValues.sort_by == null
                "
              />
              <label class="filter_label-item" for="filterStatus-0"
                ><span class="text text-dark-md">Toàn bộ</span></label
              >
              <input
                v-model="filterValues.sort_by"
                class="selection-buttons_item input-radio"
                id="filterStatus-1"
                name="sort_by"
                type="radio"
                value="popularity"
                :checked="filterValues.sort_by == 'popularity'"
              />
              <label class="filter_label-item" for="filterStatus-1"
                ><span class="text text-dark-md">Nổi bật</span></label
              >

              <input
                v-model="filterValues.sort_by"
                class="selection-buttons_item input-radio"
                id="filterStatus-2"
                name="sort_by"
                type="radio"
                value="timestamp"
                :checked="filterValues.sort_by == 'timestamp'"
              />
              <label class="filter_label-item" for="filterStatus-2"
                ><span class="text text-dark-md">Mới về</span></label
              >
              <input
                v-model="filterValues.sort_by"
                class="selection-buttons_item input-radio"
                id="filterPrice-3"
                name="sort_by"
                type="radio"
                value="on_sale_desc"
                :checked="filterValues.sort_by == 'on_sale'"
              />
              <label class="filter_label-item" for="filterPrice-3"
                ><span class="text text-dark-md">Giá giảm nhiều</span></label
              >

              <input
                v-model="filterValues.sort_by"
                class="selection-buttons_item input-radio"
                id="filterPrice-4"
                name="sort_by"
                type="radio"
                value="sort_by_price_desc"
                :checked="
                  filterValues.sort_by == 'price' && filterValues.sort_order == 'desc'
                "
              />
              <label class="filter_label-item" for="filterPrice-4"
                ><span class="text text-dark-md">Giá cao trước</span></label
              >
              <input
                v-model="filterValues.sort_by"
                class="selection-buttons_item input-radio"
                id="filterPrice-5"
                name="sort_by"
                type="radio"
                value="sort_by_price_asc"
                :checked="
                  filterValues.sort_by == 'price' && filterValues.sort_order == 'asc'
                "
              />
              <label class="filter_label-item" for="filterPrice-5"
                ><span class="text text-dark-md">Giá thấp trước </span></label
              >
            </div>
          </div>

          <div class="filter-list-item dosiin_pt-4 dosiin_pb-0">
            <p class="title text-dark-lg">Giá cả</p>

            <div class="section-title slide-view-container">
              <div class="title-view-container center">
                <span>
                  Giá từ <b>{{ numberFormat(pricesRange[0]) }} ₫ </b> đến
                  <b>{{ numberFormat(pricesRange[1]) }} ₫</b>
                </span>
              </div>
              <Slider
                class="view-container"
                v-model="pricesRange"
                :min="0"
                :max="parseInt(maxPrice)"
                :step="1000"
                style="width: 100%"
                :tooltips="false"
              ></Slider>
            </div>
          </div>
        </div>
      </form>
      <div class="filter-list dosiin_pd-016">
        <FilterBrands
          v-show="filterBrand"
          v-model="filterValues.company_ids"
          :filterModalClass="filterModalClass"
          @update="getBands"
          ref="brandFilters"
        />

        <FilterCategories
          v-show="filterCategory"
          v-model="filterValues.cid"
          :filterModalClass="filterModalClass"
          @update="getCategories"
          ref="categoryFilters"
        />

        <FilterColors
          v-show="filterColor"
          v-model="filterValues.color"
          :filterModalClass="filterModalClass"
          @update="getColors"
          ref="colorFilters"
        />

        <FilterSizes
          v-show="filterSize"
          v-model="filterValues.sizes"
          :filterModalClass="filterModalClass"
          @update="getSizes"
          ref="sizeFilters"
        />
      </div>
    </template>

    <template v-slot:footer>
      <button
        class="btn-rounded dosiin_filter-reset-btn cm-reset-link dossin_btn-whitebg"
        @click.prevent="resetFilters"
        :disabled="defaultKeyFilter == state.keyFilter"
      >
        <div class="btn-rounded_child">
          <span class="text-gradient">Đặt lại</span>
        </div>
      </button>
      <button
        class="btn-rounded btn-rounded-fill dosiin_filter-apply-btn"
        type="submit"
        @click.prevent="filter"
      >
        Xem {{ numberFormat(totalProducts) }} sản phẩm
      </button>
    </template>
  </Modal>
</template>

<script>
import { watch, reactive, ref, toRefs, inject, unref } from "vue";
import { useRoute } from "vue-router";
import FilterBrands from "./Brands";
import FilterCategories from "./Categories";
import FilterColors from "./Colors";
import FilterSizes from "./Sizes";
import { productPerPage } from "@/utlis/global";
import Slider from "@vueform/slider";
import Modal from "@/components/modals/Modal";
import { serialize } from "@/utlis/serialize";

export default {
  name: "filters",
  components: {
    FilterBrands,
    FilterCategories,
    FilterColors,
    FilterSizes,
    Modal,
    Slider,
  },
  props: {
    showFilter: {
      type: Boolean,
      default: false,
    },
    filterBrand: {
      type: Boolean,
      default: true,
    },
    filterCategory: {
      type: Boolean,
      default: true,
    },
    filterSize: {
      type: Boolean,
      default: true,
    },
    filterColor: {
      type: Boolean,
      default: true,
    },
    totalProducts: {
      type: [Number, String],
      default: 0,
    },
    filterModalClass: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object,
      default: {},
    },
    maxPrice: {
      type: [Number, String],
      default: 0,
    },
  },
  emits: ["update:modelValue", "prices-range"],
  setup(props, { emit }) {
    const route = useRoute();
    const colorFilters = ref(null);
    const brandFilters = ref(null);
    const sizeFilters = ref(null);
    const categoryFilters = ref(null);
    const currentParams = inject("params");
    const pricesRange = ref([0, 0]);

    const filterValues = reactive({
      type_category: currentParams.value.type_category,
      sort_by: currentParams.value.sort_by,
      sort_order: null,
      cid: route.params.cid
        ? [route.params.cid]
        : route.query.cid
        ? [route.query.cid]
        : [], // get from url params
      company_ids: route.params.bid ? [route.params.bid] : [], // get from url params
      color: [],
      sizes: [],
      price_from: null,
      price_to: null,
    });

    const defaultKeyFilter = serialize(filterValues); //generate key for reset button
    const filterModalClass = ref(props.filterModalClass);

    const state = reactive({
      show: props.showFilter,
      totalProducts: props.totalProducts,
      keyFilter: defaultKeyFilter,
    });

    const filterForm = ref(null);

    const closeFilters = () => {
      state.show = !state.show;
    };

    const resetFilters = () => {
      filterValues.type_category = null;
      filterValues.sort_by = "random";
      filterValues.company_ids = [];
      filterValues.color = [];
      filterValues.sizes = [];
      filterValues.cid = [];
      filterValues.price_from = null;
      filterValues.price_to = null;

      currentParams.value.type_category = null;
      currentParams.value.sort_by = "random";
      currentParams.value.company_ids = [];
      currentParams.value.color = [];
      currentParams.value.sizes = [];
      currentParams.value.cid = [];
      currentParams.value.price_from = null;
      currentParams.value.price_to = null;

      pricesRange.value[0] = 0;
      pricesRange.value[1] = props.maxPrice;

      colorFilters.value.dataProvide.value = [];
      brandFilters.value.dataProvide.value = [];
      sizeFilters.value.dataProvide.value = [];
      categoryFilters.value.dataProvide.value = [];
    };

    watch(
      () => props.showFilter,
      (openChange) => {
        state.show = openChange;
      }
    );

    watch(
      () => state.show,
      (openChange) => {
        state.show = openChange;
      }
    );

    watch(
      () => state.show,
      (totalProducts) => {
        state.totalProducts = totalProducts;
      }
    );

    watch(
      () => props.maxPrice,
      () => {
        pricesRange.value[1] = props.maxPrice;
      }
    );

    // watch slide price range
    watch(pricesRange, (newPricesRange) => {
      filterValues.price_from = newPricesRange[0];
      filterValues.price_to = newPricesRange[1];
      emit("prices-range", newPricesRange);
    });

    // watch from filter bar pc
    watch(
      () => currentParams,
      (newcurrentParams) => {
        const { type_category, sort_by, price_from, price_to } = newcurrentParams.value;

        if (type_category) {
          filterValues.type_category = type_category;
        } else {
          filterValues.type_category = "";
        }

        if (sort_by) {
          filterValues.sort_by = sort_by;
        }

        if (price_from || price_from == 0) {
          filterValues.price_from = price_from;
          pricesRange.value[0] = price_from;
        }

        if (price_to) {
          filterValues.price_to = price_to;
          pricesRange.value[1] = price_to;
        }
      },
      { deep: true }
    );

    watch(
      () => filterValues,
      () => {
        state.keyFilter = serialize(filterValues);
        var params = {
          items_per_page: productPerPage,
          sort_by: "timestamp",
          page: 1,
          area: "C",
        };

        for (const [key, value] of Object.entries(filterValues)) {
          if (value && value.length) {
            if (value == "sort_by_price_desc") {
              params["sort_by"] = "price";
              params["sort_order"] = "desc";
            } else if (value == "sort_by_price_asc") {
              params["sort_by"] = "price";
              params["sort_order"] = "asc";
            } else if (value == "on_sale_desc") {
              params["sort_by"] = "on_sale";
              params["sort_order"] = "desc";
            } else if (["sizes", "color", "company_ids", "cid"].includes(key)) {
              let result = value;
              if (route.name !== "brand_shop") {
                result = Object.values(value).map((item) => item.id);
              }
              params[key] = result.toString();
            } else {
              params[key] = value;
            }
          } else if (key == "price_from" || key == "price_to") {
            params[key] = value;
          } else {
            delete currentParams.value[key];
          }
        }

        const mergeParams = {
          ...currentParams.value,
          ...params,
        };

        emit("update:modelValue", mergeParams);
      },
      { deep: true }
    );

    const filter = () => {
      closeFilters();
    };

    const getBands = (selected) => {
      filterValues.company_ids = selected;
    };

    const getCategories = (selected) => {
      filterValues.cid = selected;
    };

    const getSizes = (selected) => {
      filterValues.sizes = selected;
    };

    const getColors = (selected) => {
      filterValues.color = selected;
    };

    return {
      state,
      ...toRefs(state),
      ...toRefs(props),
      filterValues,
      filterModalClass,
      pricesRange,
      defaultKeyFilter,
      // refs
      filterForm,
      colorFilters,
      brandFilters,
      sizeFilters,
      categoryFilters,
      //methods
      closeFilters,
      resetFilters,
      filter,
      getBands,
      getCategories,
      getSizes,
      getColors,
    };
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
