<template>
    <div class="dosiin_filter-list-item filter-list-item border-top-grey">
        <button class="title text-dark-lg dosiin_filter-category" type="button" @click.prevent="showFilterCategories = true">
            Danh mục
            <span class="filter-list-item_quantity">
                <div v-show="dataProvide.length" class="dosiin_filter-selected-option-quantity filter-quantity text-grey-md text-pink-gradient">
                    +{{dataProvide.length}}
                </div>
                <i class="dsi dsi-chevron-right dsi-s16"></i>
            </span>
        </button>
        <div class="dosiin_swipable-tag-list filter-tag-list swipable-tag-list-slider dosiin_swipable-tag-list-slider">
            <Swiper 
                v-show="dataProvide.length" 
                :slidesPerView="'auto'" 
                :observer="true"
                :observeParents="true"
                :spaceBetween="8"
                class="dosiin_tag-list tag-list swiper-wrapper"
            >
                <SwiperSlide class="swiper-slide dosiin_mr-2 swiper-slide_button clear-btn btn-circle dosiin_btn-clear-filter swiper-slide-active" @click="removeSelected('all')">
                    <i class="dsi dsi-close dsi-s16"></i>
                </SwiperSlide>

                <SwiperSlide class="tag-item dosiin_mr-2" v-for="(item , i) in dataProvide" :key="i">
                    <span class="text">{{ item.name }}</span>
                    <button class="dosiin_btn-clear-single-tag clear-tag-btn" @click="removeSelected(item.id)">
                        <i class="dsi dsi-close dsi-s12"></i>
                    </button>
                </SwiperSlide>
            </Swiper>
        </div>

        <Modal
            v-model="showFilterCategories"
            @close="closeFilterCategories"
            :modalTypeClass="filterModalClass"
        >
             <template v-slot:header>
                <h2 class="text-grey-lg">Danh mục</h2>
                <div class="back-header-right-side"></div>
            </template>
            <template v-slot:body>
                <LoadingComponent v-if="!fetched"/>
                <form 
                    v-else
                    class="dosiin_filter-categories-form"
                    ref="filterForm"
                >
                    <ul class="group-wrapper filter-categories">
                        <li class="filter-item" v-for="(category , i) in listCategory" :key="i">
                            <label class="filter-label">
                                <i class="dsi dsi-s24" :class="getCategoryIcon(category.category_id)"></i>
                                 <!-- @change="filterCategoryByIds($event.target.value)"  -->
                                <input 
                                    v-model="listIds"
                                    class="checkbox-input" 
                                    type="checkbox" 
                                    name="filterOption" 
                                    :value="category.category_id"
                                />
                                <span class="filter-label-text">{{category.category}}</span>
                                <i class="check-icon"></i>
                            </label>
                        </li>
                    </ul>
                </form>
            </template>

            <template v-slot:footer>
                <button 
                    class="btn-rounded dosiin_filter-reset-btn cm-reset-link dossin_btn-whitebg" 
                    type="button" 
                    :disabled="listIds.length === 0"
                    @click="resetFilters"
                >
                    <div class="btn-rounded_child">
                        <span class="text-gradient">Đặt lại</span>
                    </div>
                </button>
                <button 
                    class="btn-rounded btn-rounded-fill dosiin_filter-apply-btn" 
                    type="button" 
                    @click="filterByIds"
                    :disabled="listIds.length === 0"
                >
                    Áp dụng
                </button>
            </template>
        </Modal>
    </div>
</template>


<script>    
    import Modal from '@/components/modals/Modal';
    import { ref ,watch} from 'vue';
    import { serialize } from '@/utlis/serialize';
    import CategoryServices from '@/services/category';

    export default {
        name : 'filterCategories',
        components:{ Modal },
        emits :['update'],
        props:{
            filterModalClass:{
                type : String,
                default : ''
            },
            modelValue : {
                type : Array
            }
        },
        setup(props , { emit }) {
            const showFilterCategories = ref(false);
            const fetched       = ref(false);
            const listCategory  = ref([]);
            const dataProvide   = ref(props.modelValue);
            const listIds       = ref([]);
            const filterForm    = ref(null);
            const filterModalClass = ref(props.filterModalClass);
            const closeFilterCategories = () => {
                showFilterCategories.value = false;
            }

            watch(() => showFilterCategories.value , () => {
                if(fetched.value === false){
                    getCategories();
                }
            });


            const getCategories = async () => {
                var params = { filterPage : true, pageType   : 'products'};
                const response = await CategoryServices.fetchCategories(serialize(params));

                if(response.data){
                    listCategory.value = response.data.categoryList;
                }

                fetched.value = true;
            }
            
            const filterByIds = () => {
                dataProvide.value = [];
                for (const [key, item] of Object.entries(listCategory.value)) {
                    if(listIds.value.includes(item.category_id))
                        dataProvide.value.push({
                            id : item.category_id , name : item.category
                        });
                }
                emit('update',dataProvide.value);
                closeFilterCategories();
            }

            const removeSelected = (categoryId) => {
                if(categoryId === 'all'){
                    dataProvide.value = [];
                }else{
                    dataProvide.value = dataProvide.value.filter(category => category.id !== categoryId);
                }
                
                emit('update',dataProvide.value);
            }

            const resetFilters = () => {
                filterForm.value.reset();
                listIds.value     = [];
                dataProvide.value = []
                emit('update',dataProvide.value);
            }

            return {
                fetched,
                showFilterCategories,
                listCategory,
                dataProvide,
                listIds,
                filterModalClass,
                //refs
                filterForm,
                // methods
                closeFilterCategories,
                filterByIds,
                removeSelected,
                resetFilters
            }
        },
    }
</script>