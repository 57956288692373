<template>
  <main class="product-level1-page page-new-product">
    <BackHeader>
      <template v-slot:center>
        <h2 class="text-grey-lg title-header-category">
          {{ state.title }}
        </h2>
      </template>
      <template v-slot:right>
        <div class="back-header-right-side">
          <router-link
            :to="{ name: 'search' }"
            class="search-icon dosiin_search-header-btn"
          >
            <i class="dsi dsi-search"></i>
          </router-link>
        </div>
      </template>
    </BackHeader>

    <div class="product-page-header_wrap">
      <h1 class="title" v-text="state.title"></h1>
      <h2 class="subtitle" v-text="state.des"></h2>
    </div>

    <div class="page-nav-bar-sticky">
      <div
        class="page-nav-bar dosiin_page-nav-bar dosiin_filter-result-nav-bar filter-result-nav-bar"
      >
        <div class="total-result" style="display: flex">
          <b class="total-product" v-text="numberFormat(state.totalProducts)"></b>
          &nbsp;sản phẩm
        </div>

        <button class="nav-bar_filter" type="button" @click="openFilters">
          <div class="divider"></div>
          <span class="text">Bộ lọc</span>
          <i class="dsi dsi-filter-setting"></i></button
        >s
      </div>
    </div>

    <div class="page-section">
      <ProductInifityLoader
        :type="type"
        :query="params"
        :changeGrid="true"
        gridClass="grid-50"
        @total-products="(total) => (state.totalProducts = total)"
        @max-price="(max) => (state.maxPrice = max)"
      />
    </div>

    <MainFilters
      v-model="params"
      :totalProducts="state.totalProducts"
      filterModalClass="modal-sidebar-right"
      ref="mainFilters"
      :maxPrice="state.maxPrice"
    />
  </main>
</template>

<script>
import { reactive, inject, ref } from "vue";
import BackHeader from "@/components/common/BackHeader";
import ProductInifityLoader from "@/components/product/ProductInifityLoader";
import ProductsSliderHorizon from "@/components/sliders/ProductsHorizon";
import MainFilters from "@/components/filters/Filters";

export default {
  components: {
    BackHeader,
    ProductInifityLoader,
    ProductsSliderHorizon,
    MainFilters,
  },
  setup() {
    const type = inject("type");
    const params = inject("params");
    const mainFilters = ref(null);
    const state = reactive({
      title: "Sản phẩm mới",
      des: "Các sản phẩm mới nhất ở Dosi-in",
      totalProducts: 0,
      maxPrice: 0,
    });

    const openFilters = () => {
      mainFilters.value.show = !mainFilters.value.show;
    };

    return {
      state,
      type,
      params,
      openFilters,
      mainFilters,
    };
  },
};
</script>
