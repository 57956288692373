<template>
  <InfiniteLoad
    @infinite-scroll="loadMore"
    :isLoading="state.loading"
    :noResult="state.endLoad"
  >
    <div class="section-title slide-view-container" v-if="changeGrid">
      <Slider
        v-model="gridLayout"
        class="view-container"
        :min="0"
        :max="2"
        :step="-1"
        :tooltips="false"
      />
      <p :class="$style.subText">Kéo để thay đổi bố cục sản phẩm</p>
    </div>

    <ProductLoading :wrapperClass="'grid ' + gridClass" v-if="state.skeleton" />

    <div v-else-if="products.length > 0" class="grid" :class="gridClass">
      <div class="grid-item" v-for="(product, i) in products" :key="i">
        <ProductItem :product="product" :showRating="true" />
      </div>
    </div>

    <EmptyComponent v-else :title="true" />
  </InfiniteLoad>
</template>

<script>
import { ref, watch, reactive } from "vue";
import { useRoute } from "vue-router";
import ProductService from "@/services/product";
import ProductItem from "@/components/product/ProductItem";
import Pagination from "@/components/pagination/Pagination";
import ProductLoading from "@/components/skeletons/Products";
import { serialize } from "@/utlis/serialize";
import { _dsEmptyObject } from "@/utlis/helpers";
import { productGridClass } from "@/utlis/global";
import Slider from "@vueform/slider";
import InfiniteLoad from "@/components/common/InfiniteScroll";

export default {
  components: {
    InfiniteLoad,
    ProductLoading,
    ProductItem,
    Pagination,
    Slider,
  },
  props: {
    query: {
      type: Object,
      require: true,
    },
    gridClass: {
      type: String,
      default: "grid-20",
    },
    changeGrid: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Number,
      default: 1,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["totalProducts", "maxPrice", "usingParams"],
  setup(props, { emit }) {
    const route = useRoute();
    const state = reactive({
      init: false, // onintersaction
      loading: false, // show loading button
      endLoad: false, // disable button loadmore
      skeleton: true, // show skeletons
      page: 1,
      totalPage: 0,
    });

    const pagination = ref({});
    const products = ref([]);
    const gridLayout = ref(props.grid);
    const gridClass = ref(props.gridClass);

    getProducts(true, false, false);

    async function getProducts(init, changeRoute = false, push = true) {
      const queryParams = props.query;
      if (_dsEmptyObject(queryParams) || state.loading) return;

      if (!push) {
        //show loading button
        state.skeleton = true;
        products.value = [];
      } else {
        // show skeleton
        state.loading = true;
      }

      if (init) {
        var query = queryParams;
      } else {
        pagination.value.page = state.page;
        var query = pagination.value;
        queryParams.page = state.page;
      }

      if (["categories"].includes(route.name)) {
        query.cid = route.params.cid;
      }

      if (["brand_shop", "brand_shop_cat"].includes(route.name)) {
        query.company_ids = route.params.bid;
      }

      // change url with params
      if (changeRoute) updateQueryParams(queryParams);

      const response = await ProductService.fetchProducts(query);

      // show empty component if products response empty
      if (response.data.products.length === 0) {
        state.endLoad = true;
      } else {
        state.endLoad = false;
      }

      if (push) products.value.push(...response.data.products);
      else products.value = response.data.products;

      pagination.value = response.data.params;
      state.totalPage = Math.ceil(
        pagination.value.total_items / pagination.value.items_per_page
      );

      state.page = pagination.value.page;
      state.loading = false;
      state.init = true;
      state.skeleton = false;
      if (!queryParams.price_from && !queryParams.price_to) {
        emit(
          "maxPrice",
          response.data.params.max_price === null
            ? 0
            : parseInt(response.data.params.max_price)
        );
      }

      emit("totalProducts", pagination.value.total_items);
    }

    // props query change , update query
    watch(
      () => props.query,
      () => {
        if (state.init) {
          // only recivied props when component is init
          getProducts(true, true, false);
        }
      }
    );

    watch(
      () => gridLayout.value,
      (gridId) => {
        if (gridId < 0.5) gridId = 0;
        if (gridId > 0.5 && gridId < 1) gridId = 1;
        if (gridId > 1 && gridId < 1.5) gridId = 1;
        if (gridId > 1.5 && gridId <= 2) gridId = 2;

        const gridSelected = productGridClass[gridId];
        if (gridSelected) gridClass.value = gridSelected;
      },
      { immediate: props.changeGrid }
    );

    const loadMore = () => {
      if (state.endLoad || !state.init) return;
      state.page++;
      getProducts(false, true, true);
    };

    const updateQueryParams = (newParams) => {
      var count = 0;
      for (const key in newParams) {
        if (
          ["cid", "color", "sizes", "company_ids", "sort_by", "type_category"].includes(
            key
          )
        ) {
          if (key == "cid" && route.name === "categories") continue;
          if (key == "company_ids" && route.name === "brand_shop") continue;
          if (key == "sort_by" && newParams[key] == "random") continue;
          if (typeof newParams[key] == "object" && !_dsEmptyObject(newParams[key]))
            count++;
          if (typeof newParams[key] == "string" && newParams[key].length > 1) count++;
          if (typeof newParams[key] == "array" && newParams[key].length > 0) count++;
        }
      }

      delete newParams["items_per_page"];
      delete newParams["area"];

      emit("usingParams", count);

      history.replaceState({}, null, window.location.pathname + serialize(newParams));
    };

    return {
      state,
      pagination,
      products,
      gridLayout,
      gridClass,
      //methods
      loadMore,
      getProducts,
    };
  },
};
</script>

<style>
.grid.grid-50 .grid-item,
.grid.grid-33 .grid-item,
.grid.grid-11 .grid-item {
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>

<style module>
.subText {
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #b3b3b3;
  text-align: center;
}
</style>
