// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Brands_dark_wZCXv{--popper-theme-background-color:#333;--popper-theme-background-color-hover:#333;--popper-theme-text-color:#fff;--popper-theme-border-width:0px;--popper-theme-border-radius:6px;--popper-theme-padding:16px;--popper-theme-box-shadow:0 6px 30px -6px rgba(0,0,0,0.25);font-size:12px;line-height:150%}", ""]);
// Exports
exports.locals = {
	"dark": "Brands_dark_wZCXv"
};
module.exports = exports;
